var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "One of the most common features is to have the hidden sidebar to show it when the user presses a button or makes an action, to add a sidebar we have the component vs-sidebar"
        ),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "my-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v("To add an internal link using "),
            _c(
              "a",
              {
                attrs: { href: "https://router.vuejs.org/", targte: "_blank" },
              },
              [_vm._v("vue-router")]
            ),
            _vm._v(" you can do them simply by adding the property "),
            _c("a", { attrs: { href: "https://router.vuejs.org/api/#to" } }, [
              _vm._v("to"),
            ]),
            _vm._v(" as if it were a vue-router link.\n            "),
            _c("br"),
            _vm._v(
              "\n            In case you need an external link or normal html, simply do it with the href property"
            ),
          ]),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mb-1",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "To make the link is in an active state we have the property "
            ),
            _c("code", [_vm._v("v-model")]),
          ]),
        ]
      ),
      _c("div", { staticClass: "mt-5" }, [
        _c(
          "div",
          { attrs: { id: "parentx-demo-1" } },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "primary", type: "filled" },
                on: {
                  click: function ($event) {
                    _vm.active = !_vm.active
                  },
                },
              },
              [_vm._v("Open Sidebar")]
            ),
            _c(
              "vs-sidebar",
              {
                staticClass: "sidebarx sidebarpage",
                attrs: {
                  parent: "body",
                  "default-index": "1",
                  color: "primary",
                  spacer: "",
                },
                model: {
                  value: _vm.active,
                  callback: function ($$v) {
                    _vm.active = $$v
                  },
                  expression: "active",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "header-sidebar",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("vs-avatar", {
                      attrs: {
                        size: "70px",
                        src: "https://randomuser.me/api/portraits/men/85.jpg",
                      },
                    }),
                    _c(
                      "h4",
                      [
                        _vm._v(
                          "\n                            My Name\n                            "
                        ),
                        _c("vs-button", {
                          attrs: {
                            color: "primary",
                            icon: "more_horiz",
                            type: "flat",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "vs-sidebar-item",
                  { attrs: { index: "1", icon: "question_answer" } },
                  [
                    _vm._v(
                      "\n                        Dashboard\n                    "
                    ),
                  ]
                ),
                _c(
                  "vs-sidebar-item",
                  { attrs: { index: "2", icon: "gavel" } },
                  [
                    _vm._v(
                      "\n                        History\n                    "
                    ),
                  ]
                ),
                _c(
                  "vs-divider",
                  { attrs: { icon: "person", position: "left" } },
                  [
                    _vm._v(
                      "\n                        User\n                    "
                    ),
                  ]
                ),
                _c(
                  "vs-sidebar-item",
                  { attrs: { index: "3", icon: "verified_user" } },
                  [
                    _vm._v(
                      "\n                        Configurations\n                    "
                    ),
                  ]
                ),
                _c(
                  "vs-sidebar-item",
                  { attrs: { index: "4", icon: "account_box" } },
                  [
                    _vm._v(
                      "\n                        Profile\n                    "
                    ),
                  ]
                ),
                _c("vs-sidebar-item", { attrs: { index: "5" } }, [
                  _vm._v(
                    "\n                        Card\n                    "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "footer-sidebar",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { icon: "reply", color: "danger", type: "flat" },
                      },
                      [_vm._v("log out")]
                    ),
                    _c("vs-button", {
                      attrs: {
                        icon: "settings",
                        color: "primary",
                        type: "border",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n\n  <div id="parentx-demo-1">\n\n    <vs-button @click="active=!active" color="primary" type="filled">Open Sidebar</vs-button>\n    <vs-sidebar parent="body" default-index="1"  color="primary" class="sidebarx" spacer v-model="active">\n\n      <div class="header-sidebar" slot="header">\n        <vs-avatar  size="70px" src="https://randomuser.me/api/portraits/men/85.jpg"/>\n\n        <h4>\n          My Name\n          <vs-button color="primary" icon="more_horiz" type="flat"></vs-button>\n        </h4>\n\n      </div>\n\n      <vs-sidebar-item index="1" icon="question_answer"> Dashboard </vs-sidebar-item>\n      <vs-sidebar-item index="2" icon="gavel"> History </vs-sidebar-item>\n\n      <vs-divider icon="person" position="left"> User </vs-divider>\n\n      <vs-sidebar-item index="3" icon="verified_user"> Configurations </vs-sidebar-item>\n      <vs-sidebar-item index="4" icon="account_box"> Profile </vs-sidebar-item>\n      <vs-sidebar-item index="5" > Card </vs-sidebar-item>\n\n      <div class="footer-sidebar" slot="footer">\n        <vs-button icon="reply" color="danger" type="flat">log out</vs-button>\n        <vs-button icon="settings" color="primary" type="border"></vs-button>\n      </div>\n\n    </vs-sidebar>\n  </div>\n\n</template>\n\n<script>\nexport default {\n  data:()=>({\n    active:false,\n  })\n}\n</script>\n\n<style lang="scss">\n.header-sidebar {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  h4 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    > button {\n      margin-left: 10px;\n    }\n  }\n}\n\n.footer-sidebar {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  > button {\n    border: 0px solid rgba(0, 0, 0, 0) !important;\n    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;\n    border-radius: 0px !important;\n  }\n}\n</style>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }