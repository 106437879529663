var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Custom Sidebar", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "With custom sidebar you can create sidebar items and groups using "
        ),
        _c("strong", [_vm._v(" list of object")]),
        _vm._v("."),
      ]),
      _c("p", [
        _vm._v(
          "You can also use feather icons in sidebar group and sidebar items using icon property. Check out all feather icons "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://vue-feather-icons.netlify.com/",
              target: "_blank",
              rel: "nofollow",
            },
          },
          [_vm._v("here")]
        ),
      ]),
      _c("p", { staticClass: "mt-3" }, [
        _vm._v(
          "Main sidebar on left side is demo of Custom Sidebar. You can check full code by clicking on code-toggler icon of this card."
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <v-nav-menu\n    :sidebarItems="sidebarItems"\n    title="Custom"\n    :logo="navMenuLogo">\n</template>\n\n<script>\nimport VNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";\n\nexport default {\n  data: () => ({\n    showSidebar: false,\n    navMenuLogo: require(\'@/assets/images/logo/logo.png\'),\n    sidebarItems: [\n      {\n        url: "/",\n        name: "Dashboard",\n        slug: "dashboardAnalytics",\n        icon: "HomeIcon",\n        i18n: "Dashboard",\n      },\n      {\n        url: "/ui-elements/colors",\n        name: "Colors",\n        slug: "colors",\n        icon: "DropletIcon",\n        i18n: "Colors",\n      },\n      {\n        header: "Extensions",\n        i18n: "Extensions",\n      },\n      {\n        url: \'/extensions/select\',\n        name: "Select",\n        icon: "PocketIcon",\n        slug: "extraComponentSelect",\n        i18n: "Select",\n      },\n      {\n        url: \'/extensions/quill-editor\',\n        name: "Quill Editor",\n        icon: "EditIcon",\n        slug: "extraComponentQuillEditor",\n        i18n: "QuillEditor",\n      },\n      {\n        url: \'/extensions/drag-and-drop\',\n        name: "Drag & Drop",\n        icon: "DropletIcon",\n        slug: "extraComponentDragAndDrop",\n        i18n: "DragAndDrop",\n      },\n      {\n        url: \'/extensions/datepicker\',\n        name: "Datepicker",\n        icon: "CalendarIcon",\n        slug: "extraComponentDatepicker",\n        i18n: "Datepicker",\n      },\n    ]\n  }),\n  components: {\n    VNavMenu\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }