var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Static", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can also use the sidebar in static mode with the property static"
        ),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5 mb-base",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "when putting the sidebar in static mode its position becomes relative for better manipulation"
            ),
          ]),
        ]
      ),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "parentx-static relative",
            staticStyle: { height: "435px" },
          },
          [
            _c(
              "vs-sidebar",
              {
                staticClass: "sidebarx sidebarpage bordered-sidebar",
                attrs: {
                  "static-position": "",
                  "default-index": "1",
                  color: "primary",
                  spacer: "",
                },
                model: {
                  value: _vm.active,
                  callback: function ($$v) {
                    _vm.active = $$v
                  },
                  expression: "active",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "header-sidebar",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("vs-avatar", {
                      attrs: {
                        size: "70px",
                        src: "https://randomuser.me/api/portraits/men/85.jpg",
                      },
                    }),
                    _c(
                      "h4",
                      [
                        _vm._v("\n              My Name\n              "),
                        _c("vs-button", {
                          attrs: {
                            color: "primary",
                            icon: "more_horiz",
                            type: "flat",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "vs-sidebar-item",
                  { attrs: { index: "1", icon: "question_answer" } },
                  [_vm._v(" Dashboard ")]
                ),
                _c(
                  "vs-sidebar-item",
                  { attrs: { index: "2", icon: "gavel" } },
                  [_vm._v(" History ")]
                ),
                _c(
                  "vs-divider",
                  { attrs: { icon: "person", position: "left" } },
                  [_vm._v(" User ")]
                ),
                _c(
                  "vs-sidebar-item",
                  { attrs: { index: "3", icon: "verified_user" } },
                  [_vm._v(" Configurations ")]
                ),
                _c(
                  "vs-sidebar-item",
                  { attrs: { index: "4", icon: "account_box" } },
                  [_vm._v(" Profile ")]
                ),
                _c("vs-sidebar-item", { attrs: { index: "5" } }, [
                  _vm._v(" Card "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "footer-sidebar",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { icon: "reply", color: "danger", type: "flat" },
                      },
                      [_vm._v("log out")]
                    ),
                    _c("vs-button", {
                      attrs: {
                        icon: "settings",
                        color: "primary",
                        type: "border",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n\n  <div class="parentx-static">\n    <vs-sidebar static-position default-index="1" color="primary" class="sidebarx" spacer v-model="active">\n\n      <div class="header-sidebar" slot="header">\n        <vs-avatar  size="70px" src="https://randomuser.me/api/portraits/men/85.jpg"/>\n        <h4>\n          My Name\n          <vs-button color="primary" icon="more_horiz" type="flat"></vs-button>\n        </h4>\n\n      </div>\n\n      <vs-sidebar-item index="1" icon="question_answer"> Dashboard </vs-sidebar-item>\n\n      <vs-sidebar-item index="2" icon="gavel"> History </vs-sidebar-item>\n\n      <vs-divider icon="person" position="left"> User </vs-divider>\n\n      <vs-sidebar-item index="3" icon="verified_user"> Configurations </vs-sidebar-item>\n      <vs-sidebar-item index="4" icon="account_box"> Profile </vs-sidebar-item>\n      <vs-sidebar-item index="5" > Card </vs-sidebar-item>\n\n      <div class="footer-sidebar" slot="footer">\n        <vs-button icon="reply" color="danger" type="flat">log out</vs-button>\n        <vs-button icon="settings" color="primary" type="border"></vs-button>\n      </div>\n\n    </vs-sidebar>\n  </div>\n\n</template>\n\n<script>\nexport default {\n  data:()=>({\n    active:false,\n  })\n}\n</script>\n\n<style lang="scss">\n.parentx-static {\n  overflow: hidden;\n  height: 500px;\n  position: relative;\n}\n\n.header-sidebar {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  h4 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    > button {\n      margin-left: 10px;\n    }\n  }\n}\n\n.footer-sidebar {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  > button {\n    border: 0px solid rgba(0, 0, 0, 0) !important;\n    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;\n    border-radius: 0px !important;\n  }\n}\n</style>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }